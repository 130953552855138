/* Set some default styles for the entire app */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Set some styles for the form */
form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 400px;
}

/* Style the form input fields */
input {
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

/* Style the form button */
button {
  background-color: #2196f3;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
}

/* Style the form button on hover */
button:hover {
  background-color: #0d8bf1;
}

/* Style the form message */
.message {
  margin-top: 10px;
  font-size: 16px;
}